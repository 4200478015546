<template>
  <v-row
    v-if="!isLoading"
    class="app-invoice-preview"
  >
    <v-col
      cols="12"
      md="9"
    >
      <v-card>
        <v-card-text class="py-9 px-8">
          <v-row class="mb-1">
            <v-col
              cols="12"
              md="12"
            >
              <h3>{{ $t('lbl.carShop') }}</h3>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <fragment
          v-for="(carItem, indI) in carItems"
          :key="indI"
        >
          <CarShopItemCar
            v-if="carItem.product === 'cars'"
            :pos="indI"
            :item="carItem"
          />
          <CarShopItemHotel
            v-else-if="carItem.product === 'hotels'"
            :pos="indI"
            :item="carItem"
          />
        </fragment>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card
        width="100%"
        class="mb-4 mt-3 pa-4 pt-0"
      >
        <!--PRICE TOTAL-->
        <div class="d-flex justify-space-between align-center">
          <h3 class="my-2">
            {{ $t('lbl.totalPay') }}
          </h3>
          <h3>
            <h4>
              {{ priceAllPay | currency(currencyCodeCards) }}
            </h4>
          </h3>
        </div>
        <!--PRICE ONLINE-->
        <div class="d-flex justify-space-between align-center">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          > {{ $t('lbl.payOnline') }} </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ priceOnlinePay | currency(currencyCodeCards) }}
          </span>
        </div>
        <!--PRICE DESTINO-->
        <div class="d-flex justify-space-between align-center">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ $t('lbl.payDestino') }}
          </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ priceDestinoPay | currency(currencyCodeCards) }}
          </span>
        </div>
      </v-card>

      <v-card>
        <v-card-text v-if="!convertToReservation">
          <v-btn
            v-if="!checkOnRequest"
            class="my-3"
            color="success"
            block
            :loading="loadingPay"
            @click="payItems"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiAccountCashOutline }}
            </v-icon>
            <span>{{ $t('btn.payReserve') }}</span>
          </v-btn>
          <!--<v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            :loading="loadingPrint"
            @click="solicitePrint"
          >
            <v-icon
              class="mr-2"
              left
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            {{ $t('btn.printCotization') }}
          </v-btn>-->
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            :loading="loadingSend"
            @click="soliciteSend"
          >
            <v-icon
              class="mr-2"
              left
            >
              {{ icons.mdiSendOutline }}
            </v-icon>
            {{ $t('btn.sendCotization') }}
          </v-btn>
          <!--<v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
          >
            <v-icon
              class="mr-2"
              left
            >
              {{ icons.mdiSquareEditOutline }}
            </v-icon>
            {{ $t('btn.editCotization') }}
          </v-btn>-->
          <!--v-if="checkOnRequest"-->
          <v-btn
            color="primary"
            block
            :loading="loading"
            @click="soliciteReserve"
          >
            <v-icon
              class="mr-4"
              left
            >
              {{ icons.mdiClipboardCheckOutline }}
            </v-icon>
            {{ $t('btn.soliciteReserve') }}
          </v-btn>
        </v-card-text>

        <v-card-text v-if="convertToReservation">
          <v-btn
            v-if="!checkOnRequest"
            class="my-3"
            color="success"
            block
            :loading="loadingPay"
            :disabled="checkInfoPendient"
            @click="payItemsConvertToReserve"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiAccountCashOutline }}
            </v-icon>
            <span>{{ $t('btn.payReserve') }}</span>
          </v-btn>
          <v-btn
            class="my-3"
            color="primary"
            block
            :loading="loadingPay"
            :disabled="checkInfoPendient"
            @click="convertToReserve"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiUpdate }}
            </v-icon>
            <span>{{ $t('btn.reserve') }}</span>
          </v-btn>
        </v-card-text>
      </v-card>

      <!--<v-card
        width="100%"
        class="mb-4 mt-3 pa-4 pt-0"
      >
        <div class="d-flex justify-space-between align-center">
          <h4 class="my-2">
            {{ $t('lbl.payOther') }}
          </h4>
        </div>
        <div class="d-flex justify-space-between align-center">
          <b
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          > {{ $t('lbl.toRent') }} </b>
        </div>
        <div class="d-flex justify-space-between align-center">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ $t('lbl.dayExtra') }} ($ / {{ $t('lbl.dia') }})
          </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ priceRentDayExtra | currency }}
          </span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          > {{ $t('lbl.tarifaRetorno') }} ($ / km) </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ priceRentTarifRetorno | currency }}
          </span>
        </div>
      </v-card>!-->
    </v-col>

    <SidebarDataClient
      v-model="isSidebarDataClientActive"
      from="car-shop"
      :ctas-bancarias="ctasBancarias"
      @confirmPay="methodsPays"
      @showReturnDataClient="showReturnDataClient"
    />
    <SidebarDataClient
      v-model="isSidebarDataClientReserveActive"
      from="car-shop-reserve"
      :ctas-bancarias="ctasBancarias"
      @confirmSoliciteReserve="confirmSoliciteReserve"
      @showReturnDataClient="showReturnDataClient"
    />
    <SidebarDataClient
      v-model="isSidebarDataClientPrintActive"
      from="car-shop-print-cotization"
      :ctas-bancarias="ctasBancarias"
      @confirmSolicitePrint="confirmPrint"
      @showReturnDataClient="showReturnDataClient"
    />
    <SidebarDataClient
      v-model="isSidebarDataClientSendActive"
      from="car-shop-send-cotization"
      :ctas-bancarias="ctasBancarias"
      @confirmSend="confirmSend"
      @confirmSoliciteSend="confirmSoliciteSend"
      @showReturnDataClient="showReturnDataClient"
    />
    <SidebarDataClient
      v-model="isSidebarDataClientConvertToReserveActive"
      from="car-shop-convert-to-reserve"
      :ctas-bancarias="ctasBancarias"
      @confirmConvertToReserve="confirmConvertToReserve"
      @showReturnDataClient="showReturnDataClient"
    />
    <SidebarDataClient
      v-model="isSidebarDataClientConvertToReservePaysActive"
      from="car-shop-convert-to-reserve-pays"
      :ctas-bancarias="ctasBancarias"
      @methodsPaysConvertToReserve="methodsPaysConvertToReserve"
      @showReturnDataClient="showReturnDataClient"
    />

    <SidebarSendCotization
      v-if="isSidebarSendCotizationActive"
      v-model="isSidebarSendCotizationActive"
      :name-company="nameCompany"
      :name-user="nameUser"
      :name-b2b="nameB2b"
      :email-user="emailUser"
      :user="user"
      @confirmSendCotization="confirmSoliciteSendCotization"
      @showReturnDataClient="showReturnDataClient"
    />

    <SidebarDataPays
      v-if="isSidebarDataPaysActive"
      v-model="isSidebarDataPaysActive"
      from="pays"
      :user="user"
      :currency-code="currencyCode"
      :currency-symbol="currencySymbol"
      @confirmPay="confirmPay"
    />
    <SidebarDataPays
      v-if="isSidebarDataPaysConvertToReservePaysActive"
      v-model="isSidebarDataPaysConvertToReservePaysActive"
      from="convert-to-reserve-pays"
      :user="user"
      :currency-code="currencyCode"
      :currency-symbol="currencySymbol"
      @confirmConvertToReservePays="confirmConvertToReservePays"
    />

    <!--<CotizationsPdf
      :config="config"
      :email-principal="emailPrincipal"
    /> -->
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiSendOutline,
  mdiCurrencyUsd,
  mdiPrinter,
  mdiSquareEditOutline,
  mdiClipboardCheckOutline,
  mdiAccountCashOutline,
  mdiUpdate,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import SidebarDataClient from '@/views/booking/cotizador/selected/utils/SidebarDataClient.vue'
// eslint-disable-next-line import/no-unresolved
import SidebarSendCotization from '@/views/booking/cotizador/selected/utils/SidebarSendCotization.vue'
import CarShopItemCar from './utils/CarShopItemCar.vue'
import CarShopItemHotel from './utils/CarShopItemHotel.vue'
import SidebarDataPays from './utils/SidebarDataPays.vue'

// import CotizationsPdf from './utils/CotizationsPdf.vue'

export default {
  components: {
    SidebarDataClient,
    SidebarSendCotization,
    CarShopItemCar,
    CarShopItemHotel,
    SidebarDataPays,

    // CotizationsPdf,
  },
  data() {
    return {
      isLoadinCurrency: true,
      currencyCode: 'USD',
      currencySymbol: '$',
      nameB2b: null,
      nameCompany: '',
      nameUser: '',
      emailUser: '',
      btnName: '',
      isLoading: true,
      loadingPay: false,
      loading: false,
      loadingPrint: false,
      loadingSend: false,
      isSidebarDataPaysActive: false,
      isSidebarDataPaysConvertToReservePaysActive: false,
      isSidebarSendCotizationActive: false,
      isSidebarDataClientActive: false,
      isSidebarDataClientReserveActive: false,
      isSidebarDataClientPrintActive: false,
      isSidebarDataClientSendActive: false,
      isSidebarDataClientConvertToReserveActive: false,
      isSidebarDataClientConvertToReservePaysActive: false,
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiPrinter,
        mdiSquareEditOutline,
        mdiClipboardCheckOutline,
        mdiAccountCashOutline,
        mdiUpdate,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      convertToReservation: false,
      user: {},
      config: {
        logo: null,
        name: '',
      },
      emailPrincipal: null,
      ctasBancarias: [],
      cotization: {
        code: null,
      },
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      carItems: state => state.app.carItems,
      loadingBtn: state => state.app.loadingBtn,
    }),
    currencyCodeCards() {
      let result = '$'
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          result = carItem.data_service.items.currencyCode
        }
      })

      return result
    },
    priceAllPay() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          result += carItem.data_markups.priceTotal
        }
      })

      return result
    },
    priceOnlinePay() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          result += carItem.data_markups.precioSuplementPayOnline
        }
      })

      return result
    },
    priceDestinoPay() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          result += carItem.data_markups.precioSuplementPayRentator
        }
      })

      return result
    },
    priceRentDayExtra() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          if (carItem.entity === 'cars') {
            result += carItem.data_markups.tarifaDayExtra
          }
        }
      })

      return result
    },
    priceRentTarifRetorno() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          if (carItem.entity === 'cars') {
            result += carItem.data_markups.precioTarifaRetorno
          }
        }
      })

      return result
    },
    checkOnRequest() {
      let onRequest = false
      this.carItems.forEach(carItem => {
        if (carItem.entity === 'cars') {
          if (
            (carItem.data_service.items.cuposDisponibles === 0
              || carItem.data_service.items.cuposDisponibles === 'null'
              || carItem.data_service.items.cuposDisponibles === null)
            && carItem.check
          ) {
            onRequest = true
          }
        }
      })

      return onRequest
    },

    checkInfoPendient() {
      let result = false

      let contOff = 0
      this.carItems.forEach(carItem => {
        if (!carItem.check) {
          // eslint-disable-next-line no-plusplus
          contOff++
        }
      })

      if (this.carItems.length === contOff) {
        result = true
      }

      this.carItems.forEach(carItem => {
        if (carItem.entity === 'cars') {
          if (
            (!carItem.data_client.nameConductorPrincipal
              || !carItem.data_client.apellidosConductorPrincipal
              || !carItem.data_client.birthdayConductorPrincipal
              || !carItem.data_client.nationalityConductorPrincipal
              || !carItem.data_client.noDocumentConductorPrincipal)
            && carItem.check
          ) {
            result = true
          }

          if (carItem.data_client.conductorAditional) {
            if (
              !carItem.data_client.nameConductorAditional
              || !carItem.data_client.apellidosConductorAditional
              || !carItem.data_client.birthdayConductorAditional
              || !carItem.data_client.nationalityConductorAditional
              || !carItem.data_client.noDocumentConductorAditional
            ) {
              result = false
            }
          }
        } else if (carItem.entity === 'hotels') {
          carItem.data_service.ocupation.forEach(ocup => {
            if (ocup.paxs.length > 0) {
              if (!ocup.paxs[0].name || !ocup.paxs[0].apellidos || !ocup.paxs[0].noDocument) {
                result = true
              }
            }
          })
        }
      })

      return result
    },
  },
  mounted() {
    if (localStorage.getItem('car-shop') === null) {
      this.$router.push({ name: 'cotizador' })
    }

    if (localStorage.getItem('convert-to-reservation') !== null) {
      this.convertToReservation = true
    }

    if (sessionStorage.getItem('cotizations-id') !== null) {
      this.axios
        .get(`reservations/${sessionStorage.getItem('cotizations-id')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.cotization = res.data.data.data
        })
    }
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations([
      'updateItemToCar',
      'emptyClientCarShop',
      'updateLoadingBtn',
      'setCotizadorCarsResult',
      'setCotizadorCars',
      'setCotizadorCarsResultSelect',
      'setCotizadorPaxCarSelect',
      'setSearchingCar',
      'emptyClientCarShop',
      'setPosCotizador',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          this.nameCompany = this.user.company.name
          this.nameUser = `${this.user.name} ${this.user.apellidos}`
          this.emailUser = `${this.user.email}`
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getConfigData()
        })
    },
    getConfigData() {
      this.axios
        .get('configuration/data-general', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.nameB2b = res.data.data.data.data.name

            this.config = res.data.data.data.data

            if (this.config.emails_noty_reserve) {
              this.config.emails_noty_reserve.forEach(element => {
                if (element.principal) {
                  this.emailPrincipal = element.email
                }
              })
            }

            if (this.config.ctas_bancarias) {
              this.ctasBancarias = this.config.ctas_bancarias
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    printInvoice() {
      window.print()
    },
    clearCotizador() {
      // REGRESARLO AL COTIZADOR
      setTimeout(() => {
        this.$router.push({ name: 'cotizador' })
      }, 200)
      sessionStorage.setItem('clear-cotization', 'cars')
      this.setCotizadorCarsResult([])
      setTimeout(() => {
        this.setCotizadorCars({
          recogida: null,
          entrega: null,
          entregaDiffRecogida: false,
          dateRecogida: null,
          dateEntrega: null,
          transmision_id: null,
          rentadora_id: null,
        })
        this.setCotizadorCarsResultSelect([])
        this.setCotizadorPaxCarSelect({
          nameConductorPrincipal: null,
          apellidosConductorPrincipal: null,
          nameSecoundConductorPrincipal: null,
          birthdayConductorPrincipal: null,
          nationalityConductorPrincipal: null,
          sexConductorPrincipal: null,
          noDocumentConductorPrincipal: null,
          noDocumentConductorPrincipalScanner: null,
          conductorAditional: false,
          nameConductorAditional: null,
          apellidosConductorAditional: null,
          nameSecoundConductorAditional: null,
          birthdayConductorAditional: null,
          nationalityConductorAditional: null,
          sexConductorAditional: null,
          noDocumentConductorAditional: null,
          noDocumentConductorAditionalScanner: null,
        })
        this.setSearchingCar(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        localStorage.removeItem('car-shop')
      }, 100)
    },

    clearCotization() {
      // REGRESARLO A LAS RESERVAS
      setTimeout(() => {
        this.$router.push({ name: 'reservations' })
      }, 200)
      sessionStorage.setItem('clear-cotization', 'cars')
      this.setCotizadorCarsResult([])
      setTimeout(() => {
        this.setCotizadorCars({
          recogida: null,
          entrega: null,
          entregaDiffRecogida: false,
          dateRecogida: null,
          dateEntrega: null,
          transmision_id: null,
          rentadora_id: null,
        })
        this.setCotizadorCarsResultSelect([])
        this.setCotizadorPaxCarSelect({
          nameConductorPrincipal: null,
          apellidosConductorPrincipal: null,
          nameSecoundConductorPrincipal: null,
          birthdayConductorPrincipal: null,
          nationalityConductorPrincipal: null,
          sexConductorPrincipal: null,
          noDocumentConductorPrincipal: null,
          noDocumentConductorPrincipalScanner: null,
          conductorAditional: false,
          nameConductorAditional: null,
          apellidosConductorAditional: null,
          nameSecoundConductorAditional: null,
          birthdayConductorAditional: null,
          nationalityConductorAditional: null,
          sexConductorAditional: null,
          noDocumentConductorAditional: null,
          noDocumentConductorAditionalScanner: null,
        })
        this.setSearchingCar(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        this.setPosCotizador(0)
        localStorage.removeItem('car-shop')
        localStorage.removeItem('convert-to-reservation')
      }, 100)
    },

    soliciteReserve() {
      if (!this.clientCarShop.name) {
        this.isSidebarDataClientReserveActive = true
      } else {
        this.confirmSoliciteReserve()
      }
    },
    confirmSoliciteReserve() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })
        if (procesar && arrItemsCheck.length > 0) {
          this.loading = true
          this.updateLoadingBtn(true)
          const jsonCar = {
            items: arrItemsCheck,
            data_client: this.clientCarShop,
            action: 'solicitude',
          }

          this.axios
            .post('reservations/generate', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientReserveActive = false
              this.loading = false
            })
        }
      }
    },

    solicitePrint() {
      this.isSidebarDataClientPrintActive = true

      /* if (!this.clientCarShop.name || !this.clientCarShop.apellidos || !this.clientCarShop.email) {
        this.isSidebarDataClientPrintActive = true
      } else {
        this.confirmSolicitePrint()
      } */
    },
    confirmPrint() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        this.isSidebarDataClientPrintActive = false

        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })
      }
    },
    confirmSolicitePrint() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })
        if (procesar && arrItemsCheck.length > 0) {
          this.loadingPrint = true
          this.updateLoadingBtn(true)
          const jsonCar = {
            action: 'print',
            items: arrItemsCheck,
            data_client: this.clientCarShop,
          }

          this.axios
            .post('reservations/generate-cotization', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientPrintActive = false
              this.loadingPrint = false
            })
        }
      }
    },

    soliciteSend() {
      this.isSidebarDataClientSendActive = true

      /* if (!this.clientCarShop.name || !this.clientCarShop.apellidos || !this.clientCarShop.email) {
        this.isSidebarDataClientSendActive = true
      } else {
        this.confirmSoliciteSend()
      } */
    },
    confirmSend() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        setTimeout(() => {
          this.isSidebarDataClientSendActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientSendActive = true
        }, 50)
      }
    },
    showReturnDataClient() {
      setTimeout(() => {
        this.isSidebarDataClientSendActive = true
        this.isSidebarSendCotizationActive = false
      }, 50)
    },
    confirmSoliciteSend() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })
        if (procesar && arrItemsCheck.length > 0) {
          this.loadingSend = true
          this.updateLoadingBtn(true)
          const jsonCar = {
            action: 'send',
            items: arrItemsCheck,
            data_client: this.clientCarShop,
          }

          this.axios
            .post('reservations/generate-cotization', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientSendActive = false
              this.loadingSend = false
            })
        }
      }
    },
    confirmSoliciteSendCotization() {
      this.loadingSend = true
      this.updateLoadingBtn(true)
      const result = []
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          result.push(carItem)
        }
      })
      const json = {
        subject: this.clientCarShop.subject,
        message: this.clientCarShop.message,
        email: this.clientCarShop.email,
        emails: this.clientCarShop.emails,
        data_client: this.clientCarShop,
        action: 'send',
        items: result,

        // codeReserva: this.cotization.code,
      }
      this.loading = true
      this.axios

        // .post('reservations/generate-cotization-send', json, {
        .post('reservations/generate-cotization', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.clearCotizador()
            this.$toast.success(
              this.$t('msg.msgSendEmail', {
                email: this.clientCarShop.email ? this.clientCarShop.email : this.clientCarShop.emails,
              }),
            )
          }
        })
        .finally(() => {
          this.updateLoadingBtn(false)
          this.isSidebarDataClientSendActive = false
          this.isSidebarSendCotizationActive = false
          this.loadingSend = false
          this.loading = false
        })
    },

    payItems() {
      sessionStorage.setItem('actionPay', 'pays')
      sessionStorage.setItem('clientCarShop', JSON.stringify(this.clientCarShop))
      this.getCurrencyCodes()
      if (!this.clientCarShop.name /* || !this.clientCarShop.apellidos || !this.clientCarShop.email */) {
        this.isSidebarDataClientActive = true
      } else {
        this.isSidebarDataPaysActive = true

        // this.confirmPay()
      }
    },
    getCurrencyCodes() {
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          this.currencyCode = carItem.data_service.items.currency
          this.currencySymbol = carItem.data_service.items.currencyCode
        }
      })
    },
    methodsPays() {
      this.isSidebarDataPaysActive = true
    },
    confirmPay(json) {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })

        if (procesar && arrItemsCheck.length > 0) {
          this.updateLoadingBtn(true)
          const jsonCar = {
            items: arrItemsCheck,
            data_client: this.clientCarShop,
            action: 'pay',
            slug: json.slug,
            evidencia: json.evidencia,
            creditPay: json.creditPay,
            saldoFavorPay: json.saldoFavorPay,
            password: json.password,
            cardName: json.cardName,
            cardNo: json.cardNo,
            cardDateExpiret: json.cardDateExpiret,
            cardCVV: json.cardCVV,
            payWithPayPal: json.payWithPayPal,
            orderIdPayPal: json.orderIdPayPal,
            payWithSibs: json.payWithSibs,
            transactionID: json.transactionID,
          }

          this.axios
            .post('reservations/generate', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              } else if (res.data.data.status === 302) {
                this.$toast.error(this.$t('msg.passwordNotMath'))
              } else if (res.data.data.status === 401) {
                this.$toast.error(res.data.data.message)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)

              // this.isSidebarDataPaysActive = false
            })
        }
      }
    },

    convertToReserve() {
      if (!this.clientCarShop.name || !this.clientCarShop.apellidos || !this.clientCarShop.email) {
        this.isSidebarDataClientConvertToReserveActive = true
      } else {
        this.confirmConvertToReserve()
      }
    },
    confirmConvertToReserve() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })

        if (procesar && arrItemsCheck.length > 0) {
          this.loadingPay = true
          this.updateLoadingBtn(true)
          const jsonCar = {
            id: localStorage.getItem('reservations-id'),
            items: arrItemsCheck,
            data_client: this.clientCarShop,
          }

          this.axios
            .post('reservations/convert-to-reserva', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR
                this.clearCotization()
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientReserveActive = false
              this.loadingPay = false
            })
        }
      }
    },
    payItemsConvertToReserve() {
      sessionStorage.setItem('actionPay', 'convert-to-reserve-pays')
      sessionStorage.setItem('clientCarShop', JSON.stringify(this.clientCarShop))
      this.getCurrencyCodes()
      if (!this.clientCarShop.name || !this.clientCarShop.apellidos || !this.clientCarShop.email) {
        this.isSidebarDataClientConvertToReservePaysActive = true
      } else {
        this.isSidebarDataPaysConvertToReservePaysActive = true

        // this.confirmPay()
      }
    },
    methodsPaysConvertToReserve() {
      this.isSidebarDataPaysConvertToReservePaysActive = true
    },
    confirmConvertToReservePays(json) {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        const arrItemsCheck = []
        this.carItems.forEach(element => {
          if (element.check) {
            arrItemsCheck.push(element)
          }
        })

        if (procesar && arrItemsCheck.length > 0) {
          this.updateLoadingBtn(true)
          const jsonCar = {
            id: localStorage.getItem('reservations-id'),
            items: arrItemsCheck,
            data_client: this.clientCarShop,
            action: 'pay',
            slug: json.slug,
            evidencia: json.evidencia,
            creditPay: json.creditPay,
            password: json.password,
            saldoFavorPay: json.saldoFavorPay,
            payWithPayPal: json.payWithPayPal,
            orderIdPayPal: json.orderIdPayPal,
            payWithSibs: json.payWithSibs,
            transactionID: json.transactionID,
          }

          this.axios
            .post('reservations/convert-to-reserva', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR
                this.clearCotization()
              } else if (res.data.data.status === 302) {
                this.$toast.error(this.$t('msg.passwordNotMath'))
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataPaysConvertToReservePaysActive = false
              this.loadingPay = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
